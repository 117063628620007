<template>
  <div class="scanSheet">
    <detailsHeader
      ref="ExamDetailsHeader"
      router-url="/exam/unionExam"
      :query="{ examId: $route.query.examId }"
    >
    </detailsHeader>
    <div class="scan-sheet-box">
      <!-- 左边菜单盒子 -->
      <div class="scan-sheet-tab-box">
        <!-- 头部切换 -->
        <div class="tab-list">
          <template v-for="(v, i) in viewType">
            <div
              v-if="v.value != 2 || (v.value == 2 && sheetInfomationTop.isView)"
              :key="i"
              :class="{ on: indexViewType == v.value }"
              @click="checkIndexView(v.value)"
            >
              {{ v.label }}
            </div>
          </template>
        </div>
        <!-- scanTypeList -->
        <!-- 如果是扫描记录 显示 按班级  考场  批次 -->
        <div
          v-if="indexViewType == 2 && sheetInfomationTop.isView"
          class="scan-type-box"
        >
          <div class="scan-tab-box">
            <div
              v-for="(v, i) in scanTypeList"
              :key="i"
              :class="{ on: v.value == scanType }"
              @click="checkList(v.value)"
            >
              {{ v.label }}
            </div>
          </div>
          <div class="basic-information">
            <div class="check-list check-listA">
              <span>扫描点：</span>
              <el-select
                v-model="scanPointId"
                placeholder="请选择"
                filterable
                @change="checkPId()"
              >
                <!-- <el-option label="全部" :value="0"> </el-option> -->
                <!-- <el-option
                  v-if="scanType == 3"
                  label="管理员扫描点"
                  :value="-1"
                >
                </el-option> -->
                <el-option
                  v-for="item in scanItem"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div v-if="scanType == 2" class="check-list check-listA">
              <span>学&nbsp;&nbsp;&nbsp;校：</span>
              <el-select
                v-model="schoolId"
                placeholder="请选择"
                filterable
                @change="checkSchool()"
              >
                <el-option
                  v-for="item in scanSchoolItem"
                  :key="item.schoolId"
                  :label="item.schoolName"
                  :value="item.schoolId"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <el-table
            v-if="scanType == 2"
            v-loading="tabLoading"
            :data="scanMessageList"
            style="width: 100%"
            border
            :row-class-name="tableRowClassName"
            @row-click="rowclick"
          >
            <el-table-column prop="name" label="学校" align="center">
            </el-table-column>

            <el-table-column prop="planScans" label="计划人数" align="center">
            </el-table-column>
            <el-table-column
              prop="scanned"
              label="无卡人数"
              align="center"
              class="people-num"
            >
              <template slot-scope="scope">
                <span class="people-num">{{
                  scope.row.planScans - scope.row.scanned - scope.row.noScan
                }}</span></template
              >
            </el-table-column>
          </el-table>
          <el-table
            v-if="scanType == 3"
            v-loading="tabLoading"
            :data="scanMessageList"
            class="scan-batch"
            style="width: 100%"
            border
            :row-class-name="tableRowClassName"
            @row-click="rowclick"
          >
            <el-table-column prop="batch" label="批次" align="center">
              <template slot-scope="scope">
                <template v-if="!scope.row.batch"> - </template>
                <template v-else>
                  {{
                    scope.row.deviceId
                      ? scope.row.deviceId + "-" + scope.row.batch
                      : "" + (scope.row.deviceId ? "-" : "") + scope.row.batch
                  }}
                </template>
              </template>
            </el-table-column>
            <el-table-column
              prop="scanned"
              label="已扫"
              width="60"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="percentage"
              width="80"
              label="识别进度"
              align="center"
            >
            </el-table-column>
          </el-table>
          <el-table
            v-if="scanType == 1"
            v-loading="tabLoading"
            :data="scanMessageList"
            style="width: 100%"
            border
            :row-class-name="tableRowClassName"
            @row-click="rowclick"
          >
            <el-table-column prop="name" label="扫描点" align="center">
            </el-table-column>
            <el-table-column prop="planScans" label="计划人数" align="center">
            </el-table-column>
            <el-table-column prop="noScan" label="无卡人数" align="center">
              <template slot-scope="scope">
                <span class="people-num">{{
                  scope.row.planScans - scope.row.scanned - scope.row.noScan
                }}</span></template
              >
            </el-table-column>
          </el-table>
        </div>
        <div v-if="indexViewType == 1" class="paper-msg">
          <div class="basic-information">
            <div
              class="basic-information-title"
              :style="{ 'margin-bottom': !showMsg ? '0' : '18px' }"
            >
              <span>基础信息</span
              ><el-button type="text" @click="showMsg = !showMsg">
                {{ showMsg ? "收起" : "展开" }}
              </el-button>
            </div>
            <template v-if="showMsg">
              <p class="paper-msg-list">
                <span>学段：</span>{{ examQuery.level | setName(levelOptions) }}
              </p>
              <p class="paper-msg-list">
                <span>年级：</span>{{ sheetInfomationTop.gradeName }}
              </p>
              <p class="paper-msg-list">
                <span>学科：</span>{{ sheetInfomationTop.subjectName }}
              </p>
              <p class="paper-msg-list">
                <span>学校：</span>{{ sheetInfomationTop.schoolName }}
              </p>
              <p v-if="sheetInfomationTop.templateVO" class="paper-msg-list">
                <span>模板：</span
                >{{ sheetInfomationTop.templateVO.templateName }}
              </p>
              <div v-if="sheetInfomationTop.templateVO">
                <p class="paper-msg-list">
                  <span>纸张尺寸：</span>
                  <template v-if="sheetInfomationTop.templateVO.paperType == 1">
                    A4
                  </template>
                  <template v-if="sheetInfomationTop.templateVO.paperType == 2">
                    A3
                  </template>
                  <template v-if="sheetInfomationTop.templateVO.paperType == 3">
                    B4
                  </template>
                </p>
                <p class="paper-msg-list">
                  <span>纸张样式：</span>
                  <template v-if="sheetInfomationTop.templateVO.colorType == 1">
                    黑卡
                  </template>
                  <template v-if="sheetInfomationTop.templateVO.colorType == 2">
                    红卡
                  </template>
                </p>
                <!-- colorType -->
                <p class="paper-msg-list">
                  <span>试卷样式：</span>
                  {{ sheetInfomationTop.templateVO.numberCount }}张{{
                    sheetInfomationTop.templateVO.pageCount
                  }}面
                </p>
                <p class="paper-msg-list">
                  <span>客观题数：</span>
                  {{ sheetInfomationTop.templateVO.objectiveNum }}
                </p>
                <p class="paper-msg-list">
                  <span>主观题数：</span>
                  {{ sheetInfomationTop.templateVO.subjectiveNum }}
                </p>
              </div>
            </template>
          </div>
          <!-- <p class="paper-msg-title">{{ sheetInfomation.examName }}</p> -->
          <div class="basic-information">
            <div class="check-list">
              <span>扫描点：</span>
              <el-select
                v-model="scanPointId"
                placeholder="请选择"
                filterable
                @change="checkPId()"
              >
                <!-- <el-option label="全部" :value="0"> </el-option> -->
                <!-- <el-option label="管理员扫描点" :value="-1"> </el-option> -->
                <el-option
                  v-for="item in scanItem"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="check-list">
              <span>学&nbsp;&nbsp;&nbsp;校：</span>
              <el-select
                v-model="schoolId"
                placeholder="请选择"
                filterable
                @change="checkSchool()"
              >
                <el-option
                  v-for="item in scanSchoolItem"
                  :key="item.schoolId"
                  :label="item.schoolName"
                  :value="item.schoolId"
                >
                </el-option>
              </el-select>
            </div>
            <p class="paper-msg-list">
              <span>考生人数：</span> {{ sheetInfomation.examStudentNum }}
            </p>
            <p class="paper-msg-list">
              <span>正常卡人数：</span> {{ sheetInfomation.normalRecordNum }}
            </p>
            <p class="paper-msg-list">
              <span>缺考卡人数：</span> {{ sheetInfomation.missRecordNum }}
            </p>
            <p class="paper-msg-list">
              <span>无卡人数：</span>{{ sheetInfomation.noRecordNum }}
            </p>
            <p class="paper-msg-list">
              <span>已核准无卡：</span
              >{{ sheetInfomation.confirmMissRecordNum }}
            </p>
          </div>
        </div>
      </div>
      <clientSideInteraction
        v-show="indexViewType == 1"
        ref="clientSideInteraction"
        :sheet-infomation="sheetInfomation"
        :index-view-type="indexViewType"
        :paper-list="paperList"
        @checkTab="checkTab"
      ></clientSideInteraction>
      <sweepRecord
        v-show="indexViewType == 2"
        ref="sweepRecord"
        :scan-type="scanType"
        :index-view-type="indexViewType"
        :sheet-infomation="sheetInfomation"
        :exam-information="examQuery"
        :index-scan="indexScan"
        :paper-list="paperList"
        :scan-message-list="scanMessageList"
        @scanrecordbycondition="scanrecordbycondition"
        @getNewScanNum="getNewScanNum"
      ></sweepRecord>
    </div>
  </div>
</template>

<script>
const viewType = [
  {
    label: "扫描答卷",
    value: 1,
  },
  {
    label: "扫描记录",
    value: 2,
  },
];
const scanTypeList = [
  {
    label: "查看扫描点",
    value: 1,
  },
  {
    label: "查看学校",
    value: 2,
  },
  {
    label: "查看批次",
    value: 3,
  },
];
import { levelOptions } from "@/core/util/constdata.js";
import detailsHeader from "@/components/examinationHeader.vue";
import clientSideInteraction from "./components/clientSideInteraction";
import sweepRecord from "./components/sweepRecord";
// import { scanrecordbycondition } from "@/core/api/exam/examScanSheet";
import {
  getexamscanpoint,
  getschoollist,
  answersheetScanrecord,
  scanrecordunionAnswersheet,
  scanrecordbycondition,
} from "@/core/api/exam/examUnionScan";
import { getObj } from "@/core/api/exam/exam";
export default {
  name: "ScanSheet",
  components: {
    detailsHeader,
    clientSideInteraction,
    sweepRecord,
  },
  data() {
    return {
      scanItem: [],
      scanPointId: 0,
      scanSchoolItem: [],
      schoolId: 0,
      options: [],
      value: "",
      //扫描答卷  扫描记录
      viewType: viewType,
      indexViewType: 1,
      // 查看扫描类型
      scanTypeList: scanTypeList,
      scanType: 1,
      // 学段
      levelOptions: levelOptions(),
      // 从上一页传过来的基本信息
      examQuery: {},
      // 答题卡的基本信息
      sheetInfomation: {},
      sheetInfomationTop: {
        templateVO: {},
      },
      examInformation: {},
      scanMessageList: [],
      // 当前选中的子项
      indexScan: {},
      paperList: [],
      tabLoading: false,
      showMsg: false,
    };
  },
  async created() {
    this.examQuery = JSON.parse(JSON.stringify(this.$route.query));

    this.getObj();
    this.scanrecordunionAnswersheet();
    await this.getexamscanpoint();
    this.getSchoolList();
    this.scanrecordAnswersheet();
  },
  destroyed() {
    clearInterval(this.getSheetInfomation);
  },
  methods: {
    checkSchool() {
      if (this.indexViewType == 1) {
        this.scanrecordAnswersheet();
      } else {
        this.indexScan = {};
        this.$refs.sweepRecord.scanrecordAnswersheet();
        this.scanrecordbycondition();
      }
    },
    async getexamscanpoint() {
      let data = {
        examId: this.$route.query.examId,
        examPaperId: this.$route.query.examPaperId,
      };
      if (this.scanType == 3 && this.indexViewType == 2) {
        data.isBatch = 1;
      }
      await getexamscanpoint(data).then((res) => {
        this.scanItem = res.data.data;
        this.scanPointId = res.data.data[0].id;
      });
    },
    checkPId() {
      this.schoolId = 0;

      if (this.indexViewType == 1) {
        this.getSchoolList();
        this.scanrecordAnswersheet(1);
      } else {
        this.indexScan = {};
        if (this.scanType == 2) {
          this.getSchoolList();
        }
        clearInterval(this.getSheetInfomation);

        this.scanrecordbycondition();
        this.$refs.sweepRecord.scanrecordAnswersheet();
      }
    },
    async getSchoolList() {
      if (!this.$route.query.examId) return;
      let data = { examId: this.$route.query.examId, id: this.scanPointId };
      await getschoollist(data).then((res) => {
        this.scanSchoolItem = res.data.data || [];
      });
    },
    async scanrecordunionAnswersheet() {
      let data = { examPaperId: this.$route.query.examPaperId };
      await scanrecordunionAnswersheet(data).then((res) => {
        this.sheetInfomationTop = res.data.data;
      });
    },
    getNewScanNum() {
      this.scanrecordbycondition("getNewNum");
    },
    goBack() {
      this.$router.push({
        name: "examlist",
        query: { examId: this.$route.query.examId },
      });
    },
    checkSubject(v) {
      // console.log(v.paperId);
      this.examQuery.examPaperId = v.value;
      this.indexPaperId = v.paperId;
      // console.log(Object.assign({}, this.examQuery));
      this.$router.push({
        query: {
          examPaperId: this.examQuery.examPaperId,
          examId: this.examQuery.examId,
          level: this.examQuery.level,
          examType: this.examQuery.examType,
          date: new Date().getTime(),
        },
      });
      this.$nextTick(() => {
        this.$refs.ExamDetailsHeader.init(this.examQuery.examPaperId);
      });
      this.scanrecordAnswersheet();
      this.scanrecordbycondition();
      this.scanrecordunionAnswersheet();
    },
    //获取考试基本信息
    getObj() {
      getObj(this.examQuery.examId).then((res) => {
        this.examQuery = Object.assign(this.examQuery, res.data.data);
        //获取当前年级班级

        // console.log(this.examQuery);
        this.examInformation = res.data.data;
        this.paperList = res.data.data.paperList.map((item) => {
          if (item.statusSheet == 1) {
            item.label = item.subjectName;
            item.value = item.paperId;
            return item;
          } else {
            return null;
          }
        });
        this.paperList = this.paperList.filter((item) => item);
        this.indexPaperId = this.examQuery.examPaperId;
        this.$nextTick(() => {
          this.$refs.ExamDetailsHeader.init(this.examQuery.examPaperId);
        });
        // console.log(res);
      });
    },
    checkTab() {
      // console.log("41111");
      this.indexViewType = 2;
      this.scanType = 3;
      this.indexScan = {};
      this.scanrecordbycondition();
    },
    tableRowClassName({ row }) {
      // console.log(row);
      let str = "";
      if (row.planScans - row.scanned - row.noScan > 0) {
        str = " error-text";
      }
      // if (row.noScan > 0) {
      //   str = " error-text";
      // }
      if (this.indexScan == row) {
        return "index-row" + str;
      } else {
        return "" + str;
      }
    },
    rowclick(v) {
      this.indexScan = v;
      // this.scanPointId = v.examScanPointId;
      this.$nextTick(() => {
        this.$refs.sweepRecord.scanrecordAnswersheet();
        this.$refs.sweepRecord.checkBatchList();
      });
    },
    scanrecordbycondition(val) {
      if (!this.examQuery.examPaperId) return;
      let data = {
        examPaperId: this.examQuery.examPaperId,
        type: this.scanType,
        examScanPointId: this.scanPointId,
      };
      if (this.scanType == 2) {
        data.schoolId = this.schoolId;
      }
      if (val != "getNewNum") {
        this.scanMessageList = [];
      }

      this.tabLoading = true;
      scanrecordbycondition(data).then((res) => {
        this.tabLoading = false;

        if (res.config.params.type != this.scanType) {
          return;
        }
        // if(this.scanType)
        this.scanMessageList = res.data.data;
        // 加判断  核准无卡跳出当前页面
        if (val == "getNewNum") return;

        if (this.scanMessageList.length > 0) {
          if (!val) {
            this.indexScan = this.scanMessageList[0];
            if (this.indexViewType == 2) {
              this.$nextTick(() => {
                this.$refs.sweepRecord.checkBatchList();
              });
            }
          }
        } else {
          this.tabLoading = false;
          this.indexScan = {};
        }

        // console.log(res);
      });
    },
    checkList(val) {
      if (this.scanType == val) {
        return;
      }
      this.indexScan = {};
      this.schoolId = 0;
      this.scanPointId = this.scanItem[0].id;
      this.scanType = val;
      this.getexamscanpoint();
      this.scanrecordbycondition();
      this.$nextTick(() => {
        this.$refs.sweepRecord.scanrecordAnswersheet();
      });
    },
    async checkIndexView(val) {
      this.indexViewType = val;
      this.schoolId = 0;
      this.scanPointId = this.scanItem[0].id;
      if (this.indexViewType == 1) {
        await this.getexamscanpoint();
        this.getSchoolList();
        this.scanrecordAnswersheet();
      } else {
        await this.getexamscanpoint();
        this.scanrecordbycondition();
        this.$refs.sweepRecord.scanrecordAnswersheet();
      }

      // this.indexPaperId = val;
      // this.$nextTick(() => {
      //   this.$refs.ExamDetailsHeader.init(this.indexPaperId);
      // });
    },
    scanrecordAnswersheet(val) {
      if (!this.examQuery.examPaperId) return;

      let data = {
        examPaperId: this.examQuery.examPaperId,
        schoolId: this.schoolId,
        id: this.scanPointId,
      };
      if (this.$route.name != "unionscanSheet") {
        return;
      }
      answersheetScanrecord(data).then((res) => {
        this.sheetInfomation = res.data.data || {};

        if (val) {
          if (this.indexViewType == 1) {
            // this.getSheetInfomation = setTimeout(() => {
            //   this.scanrecordAnswersheet(1);
            // }, 10000);
          } else {
            clearInterval(this.getSheetInfomation);
          }
          return;
        }
        this.$nextTick(() => {
          if (this.indexViewType == 1) {
            // this.getSheetInfomation = setTimeout(() => {
            //   this.scanrecordAnswersheet(1);
            // }, 10000);
            this.$refs.clientSideInteraction.createWs();
          } else {
            this.$refs.sweepRecord.loadView();
            clearInterval(this.getSheetInfomation);
          }
        });
      });
    },
    pushMsg() {},
  },
};
</script>
<style lang="scss" scoped>
.scanSheet {
  .scan-batch {
    ::v-deep .cell {
      padding-left: 6px;
      padding-right: 6px;
    }
  }
  padding: 24px 0 64px;
  .el-dropdown-link {
    cursor: pointer;
    color: $primary-color;
    font-size: 16px;
  }
  .header-box {
    background-color: #ffffff;
    padding: 24px 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .el-select {
      width: 112px;
      height: 32px;
    }
    .el-input {
      width: 192px;
    }
    .header-search-box {
      font-weight: 400;
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;
      .search-item {
        font-size: 14px;
        margin-right: 24px;
        margin-bottom: 24px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .btn-list {
      margin-bottom: 24px;
    }
    .title {
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 24px;
      color: #080a09;
      // width: 100%;
      .el-icon-arrow-left {
        cursor: pointer;
      }
    }
  }
  ::v-deep .el-table__row {
    cursor: pointer;
  }
  ::v-deep .error-text {
    .people-num {
      color: red;
    }
  }
  ::v-deep .index-row {
    background-color: #f6fdff;
    color: $primary-color;

    td {
      &:first-child {
        position: relative;
        &::after {
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          width: 2px;
          height: 100%;
          background-color: $primary-color;
        }
      }
    }
  }
  .scan-sheet-box {
    display: flex;
    align-items: flex-start;
    margin-top: 24px;

    .check-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      .el-select {
        width: 162px;
      }
      > span {
        white-space: nowrap;
      }
    }
    .check-listA {
      .el-select {
        width: 200px;
      }
    }

    .scan-sheet-tab-box {
      // margin-top: 24px;
      margin-right: 24px;
      flex-shrink: 0;
      background: #ffffff;
      width: 300px;
      .paper-msg {
        padding: 18px;
        color: #2f3130;
        .basic-information {
          border: 1px solid #dee0e7;
          border-radius: 4px;
          padding: 18px;
          &:first-child {
            margin-bottom: 24px;
          }
        }
        .basic-information-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
        }
        .paper-msg-title {
          font-weight: 500;
          margin-bottom: 30px;
        }
        .sheet-box {
          padding: 24px;
          background: #fbfbfb;
        }
        .paper-msg-list {
          margin-bottom: 12px;
          &:last-child {
            margin-bottom: 0;
          }
          span {
            color: #606266;
          }
        }
      }
      .scan-type-box {
        padding: 24px 18px;
        .scan-tab-box {
          display: flex;
          border-radius: 4px;
          overflow: hidden;
          margin-bottom: 24px;

          .on {
            background-color: $primary-color;
            color: #ffffff;
            border-color: $primary-color;
          }
          div {
            padding: 7px 5px;
            width: 33.33%;
            cursor: pointer;
            border: 1px solid #d9d9d9;
            text-align: center;
            &:first-child {
              border-right: none;
              border-radius: 4px 0 0 4px;
            }
            &:last-child {
              border-left: none;
              border-radius: 0 4px 4px 0;
            }
          }
        }
      }
      .tab-list {
        display: flex;
        border-bottom: 1px solid #d8d8d8;
        text-align: center;
        justify-content: space-between;

        div {
          width: 50%;
          position: relative;
          padding: 24px 0;
          cursor: pointer;
        }
        .on {
          color: $primary-color;
          &::after {
            content: "";
            width: 40px;
            height: 2px;
            background-color: $primary-color;
            position: absolute;
            left: 50%;
            bottom: 0px;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -o-transform: translateX(-50%);
          }
        }
      }
    }
  }
}
</style>
