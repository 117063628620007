<template>
  <div class="clearStudent">
    <el-dialog
      title="清空答题卡"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="448px"
    >
      <div class="tips">
        <img src="@/assets/uExam/icon_waring.png" alt="" />
        1、清空答题卡将会完全删除学生答题卡及成绩数据
      </div>

      <div class="model-title">
        <span v-if="$parent.scanType == 2"> 请选择需要清空的学校 </span>
        <span v-if="$parent.scanType == 3"> 请选择需要清空的批次 </span>
        <span v-if="$parent.scanType == 1"> 请选择需要清空的扫描点 </span>
      </div>
      <el-input
        v-model="keyword"
        placeholder="搜索"
        @keyup.enter.native="handleClick"
      >
        <i
          slot="suffix"
          class="el-icon-search el-input__icon"
          @click="handleClick"
        >
        </i>
      </el-input>
      <div class="check-list-box">
        <div class="title">
          <div>
            <span class="span-btn" @click="checkChange(1)">全选</span>
            <span class="span-btn" @click="checkChange(2)">反选</span>
          </div>
          <div class="text-box">
            已选
            <span>{{ checkList.length }}</span>

            <span v-if="$parent.scanType == 2"> 所学校 </span>
            <span v-if="$parent.scanType == 3"> 个批次 </span>
            <span v-if="$parent.scanType == 1"> 个扫描点 </span>
          </div>
        </div>
        <div class="check-cnt edit-scroll-style">
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              v-for="(v, i) in schoolList"
              :key="i"
              :label="v.schoolId"
            >
              {{ v.schoolName }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="showPassword()">确 定</el-button>
      </span>
    </el-dialog>
    <passwordTips ref="passwordTips"></passwordTips>
  </div>
</template>

<script>
import passwordTips from "./passwordTips.vue";

export default {
  name: "ClearStudent",
  components: { passwordTips },
  data() {
    return {
      dialogVisible: false,
      keyword: "",
      schoolList: [],
      checkList: [],
    };
  },
  created() {},
  methods: {
    succPass() {
      this.dialogVisible = false;
      this.$parent.getNewView();
    },
    showPassword() {
      let str = "";
      if (this.scanType == 2) {
        str = "请先选择学校";
      }
      if (this.scanType == 1) {
        str = "请先选择扫描点";
      }
      if (this.scanType == 3) {
        str = "请先选择批次";
      }
      if (this.checkList.length == 0) {
        this.$message({
          showClose: true,
          message: str,
          type: "warning",
        });
        return;
      }
      this.$refs.passwordTips.init();
    },
    checkChange(type) {
      if (type == 1) {
        let arr = this.schoolList.map((item) => item.schoolId);
        this.checkList = this.checkList.concat(arr);
        this.checkList = [...new Set(this.checkList)];
      }
      if (type == 2) {
        let arr = this.schoolList.map((item) => item.schoolId);
        // 过滤出原本应该有的 但是又不在当前显示列表的
        let array = this.checkList.filter((item) => !arr.includes(item));
        arr = arr.filter((item) => !this.checkList.includes(item));
        this.checkList = array.concat(arr);
      }
    },
    submit() {},
    handleClick() {
      this.schoolList = this.oldScohhoList.filter((item) => {
        if (
          !this.keyword ||
          (this.keyword &&
            item.schoolName &&
            item.schoolName.indexOf(this.keyword) != -1)
        ) {
          return item;
        }
      });
    },
    async init() {
      // 判断下 强行修改下数据  提取出来
      this.scanType = this.$parent.scanType;
      let arr = [];
      if (this.scanType == 3) {
        arr = this.$parent.scanMessageList.map((item) => {
          item.schoolName = item.deviceId
            ? item.deviceId + "-" + item.batch
            : "" + (item.deviceId ? "-" : "") + item.batch;
          item.schoolId = item.deviceId
            ? item.deviceId + "-" + item.batch
            : "" + (item.deviceId ? "-" : "") + item.batch;
          if (!item.deviceId) {
            item.schoolId = 0;
          }
          return item;
        });
        // arr = arr.filter((item) => item.batch != "整体");
      }
      if (this.scanType == 2) {
        arr = this.$parent.scanMessageList.map((item) => {
          item.schoolName = item.name;
          if (!item.schoolId) {
            item.schoolId = 0;
          }
          return item;
        });
        // arr = arr.filter((item) => item.name != "整体");
      }
      if (this.scanType == 1) {
        arr = this.$parent.scanMessageList.map((item) => {
          item.schoolName = item.name;
          item.schoolId = item.examScanPointId;
          if (!item.schoolId) {
            item.schoolId = 0;
          }
          return item;
        });
        // arr = arr.filter((item) => item.name != "整体");
      }

      this.oldScohhoList = JSON.parse(JSON.stringify(arr));
      this.schoolList = JSON.parse(JSON.stringify(arr));
      this.checkList = [];
      this.dialogVisible = true;
    },
  },
};
</script>
<style scoped lang="scss">
.clearStudent {
  .check-list-box {
    width: 100%;
    border: 1px solid #dee0e7;
    margin-top: 18px;
    .check-cnt {
      padding: 18px;
      padding-top: 0;
      height: 322px;
      .list-box {
        .check-list {
          display: flex;
          justify-content: space-between;
          margin-top: 18px;
          i {
            cursor: pointer;
          }
        }
      }
      .el-checkbox {
        display: block;
        margin-top: 18px;
        margin-bottom: 0;
      }
    }
    .title {
      padding: 10px 18px;
      background: #f5f7fa;
      border-bottom: 1px solid #dee0e7;
      display: flex;
      justify-content: space-between;
      .text-box {
        span {
          color: #2474ed;
        }
      }

      .span-btn {
        margin-right: 18px;
        cursor: pointer;
      }
    }
  }
  .el-input {
    width: 214px;
  }
  .school-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tips {
    padding: 10px 18px;
    background: #fff3f3;
    color: #292626;
    margin-bottom: 18px;
  }
  .model-title {
    color: #2474ed;
    padding: 18px 0;
  }
}
</style>
