<template>
  <div class="noAnswerSheet">
    <el-dialog title="无卡人数统计" :visible.sync="dialogVisible" width="755px">
      <div class="filter-box">
        <div class="filter-item">
          <div>
            <span>学校：</span>
            <el-select
              v-model="searchData.schoolId"
              placeholder="请选择"
              @change="getList()"
            >
              <!-- <el-option label="全部" value=""> </el-option> -->
              <el-option
                v-for="item in scanSchoolItem"
                :key="item.schoolId"
                :label="item.schoolName"
                :value="item.schoolId"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <span>学科：</span>
            <el-select
              v-model="searchData.examPaperId"
              placeholder="请选择"
              @change="getList()"
            >
              <el-option label="全部" value=""> </el-option>
              <el-option
                v-for="item in paperList"
                :key="item.paperId"
                :label="item.subjectName"
                :value="item.paperId"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <el-button
          type="primary"
          :loading="btnLoading"
          @click="exportNoscanrecord()"
          >导出全部数据</el-button
        >
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column
          prop="schoolName"
          label="名称"
          width="193"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="allNum"
          label="无卡总人数"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-for="(item, index) in subjuectList"
          :key="index"
          :label="item.subjectName"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ getValue(item.subjectId, row) }}
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        > -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { countNoscanrecord } from "@/core/api/exam/examUnionScan";
import {
  getschoollist,
  exportNoscanrecord,
} from "@/core/api/exam/examUnionScan";
import { getObj } from "@/core/api/exam/exam";
import { fileDownloadByUrl } from "@/core/util/util";
export default {
  name: "NoAnswerSheet",
  components: {},
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      tableData: [],
      paperList: [],
      subjuectList: [],
      scanSchoolItem: [],
      searchData: {
        schoolId: 0,
        examPaperId: "",
      },
    };
  },
  created() {},
  methods: {
    //获取考试基本信息
    getObj() {
      getObj(this.$route.query.examId).then((res) => {
        this.paperList = res.data.data.paperList;
      });
    },
    async getSchoolList() {
      let data = { examId: this.$route.query.examId };
      await getschoollist(data).then((res) => {
        this.scanSchoolItem = res.data.data;
      });
    },
    getValue(subjectId, list) {
      let num = 0;
      list.subjectVOS.map((item) => {
        if (item.subjectId == subjectId) {
          num = item.num;
        }
      });
      return num;
    },
    getList() {
      let data = {
        examId: this.$route.query.examId,
      };
      Object.assign(data, this.searchData);
      countNoscanrecord(data).then((res) => {
        this.tableData = res.data.data;
        this.subjuectList = res.data.data[0].subjectVOS;
      });
    },
    exportNoscanrecord() {
      let data = {
        examId: this.$route.query.examId,
      };
      this.btnLoading = true;
      exportNoscanrecord(data)
        .then((res) => {
          this.btnLoading = false;
          let { name, url } = res.data.data;
          fileDownloadByUrl(url, name);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    init() {
      this.dialogVisible = true;
      this.getList();
      this.getSchoolList();
      this.getObj();
    },
  },
};
</script>
<style scoped lang="scss">
.noAnswerSheet {
  .filter-box {
    display: flex;
    margin-bottom: 18px;
    align-items: center;
    justify-content: space-between;
    .el-select {
      width: 140px;
      margin-right: 18px;
    }

    .filter-item {
      display: flex;
    }
  }
}
</style>
