import request from "@/core/services/axios";

//联考-根据用户权限查询扫描点
export function getexamscanpoint(query) {
  return request({
    url: "/scan/scanrecordunion/getexamscanpoint",
    method: "get",
    params: query,
  });
}

//联考-根据扫描点查询关联学校
export function getschoollist(query) {
  return request({
    url: "/scan/scanrecordunion/getschoollist",
    method: "get",
    params: query,
  });
}

//联考-根据扫描点-扫描学校-查询识别基础信息
export function answersheetScanrecord(query) {
  return request({
    url: "/scan/scanrecordunion/answersheet/scanrecord",
    method: "get",
    params: query,
  });
}

//联考-获取答题卡基本信息
export function scanrecordunionAnswersheet(query) {
  return request({
    url: "/scan/scanrecordunion/answersheet",
    method: "get",
    params: query,
  });
}

//联考-扫描识别按批次的分页列表接口
export function scanrecordunionPage(query) {
  return request({
    url: "/scan/scanrecordunion/page",
    method: "get",
    params: query,
  });
}

//联考-扫描识别按批次的分页列表接口
export function scanrecordunionAll(query) {
  return request({
    url: "/scan/scanrecordunion/all",
    method: "get",
    params: query,
  });
}

//联考-获取查询条件的扫描统计数据
export function scanrecordbycondition(query) {
  return request({
    url: "/scan/scanrecordunion/get/scanrecordbycondition",
    method: "get",
    params: query,
  });
}

//联考-按批次查询列表
export function scanrecordbatch(query) {
  return request({
    url: "/scan/scanrecordunion/get/scanrecordbatch",
    method: "get",
    params: query,
  });
}

//联考-按学校查询列表
export function scanrecordschool(query) {
  return request({
    url: "/scan/scanrecordunion/get/scanrecordschool",
    method: "get",
    params: query,
  });
}

//联考-按扫描点查询列表
export function scanrecordexamscanpoint(query) {
  return request({
    url: "/scan/scanrecordunion/get/scanrecordexamscanpoint",
    method: "get",
    params: query,
  });
}

//联考-无卡人数统计
export function countNoscanrecord(query) {
  return request({
    url: "/scan/scanrecordunion/count/noscanrecord",
    method: "get",
    params: query,
  });
}

//联考-无卡人数统计
export function exportNoscanrecord(query) {
  return request({
    url: "/scan/scanrecordunion/export/count/noscanrecord",
    method: "get",
    params: query,
  });
}
