<template>
  <div class="clientSideInteraction">
    <div class="client-side-header">
      <div>
        <i class="el-icon-warning-outline"></i>状态：
        <span v-if="clientSideLinkType" class="succ"> 连接正常 </span>
        <span v-else class="err">
          <span v-if="returnMsg" class="waring">{{ returnMsg }}</span>
          <template v-else>扫描客户端未运行</template>
        </span>
        <template v-if="!clientSideLinkType">
          立即<span class="succ" @click="downCleint">下载扫描客户端</span>
        </template>
      </div>

      <div class="client-set">
        <el-button type="text" icon="el-icon-refresh-right" @click="createWs">
          刷新
        </el-button>

        <el-button
          type="text"
          icon="el-icon-setting"
          :disabled="drivePanel"
          @click="setScanparameter()"
        >
          设置扫描参数
        </el-button>
        <el-checkbox
          v-model="drivePanel"
          style="margin-left: 12px"
          @change="setDrivePanel()"
        >
          调用驱动面板设置
        </el-checkbox>
      </div>
    </div>
    <!-- 如果是有扫描信息的情况 -->
    <template v-if="scanInformation.schoolScan >= 0">
      <div class="client-side-box scan-msg-box">
        <div class="scan-msg-list">
          <div class="scan-msg">
            <p>{{ scanInformation.localMachine }}</p>
            <span>本机已扫</span>
          </div>
          <span class="scan-tips"
            >整体已扫{{ scanInformation.schoolScan }}张</span
          >
        </div>
        <div class="scan-msg-list" @click="linkToErr">
          <div class="scan-msg">
            <p>{{ scanInformation.incompletePicture }}</p>
            <span>图像不完整</span>
          </div>
          <span class="scan-tips">处理</span>
        </div>
        <div class="scan-msg-list" @click="linkToErr">
          <div class="scan-msg">
            <p>{{ scanInformation.abnormalPicture }}</p>
            <span>识别异常</span>
          </div>
          <span class="scan-tips">处理</span>
        </div>
        <!-- <img src="@/static/scanSheet/client-side-succ.png" alt="" /> -->
      </div>
    </template>
    <!-- 没有扫描信息的情况 -->
    <template v-else>
      <!-- 扫描仪连接成功 -->
      <div
        v-if="clientSideType"
        class="client-side-box client-side-box-loading"
      >
        <div v-if="clientSideLinkType" class="load-cient">扫描仪连接成功</div>
        <div v-else class="load-cient">
          <img src="@/static/scanSheet/winfo-icon.png" alt="" />扫描仪状态检测中
        </div>

        <!-- <img src="@/static/scanSheet/client-side-succ.png" alt="" /> -->
      </div>
      <!-- 扫描仪链接失败 -->
      <div v-else class="client-side-box-waring client-side-box">
        <div class="load-cient">
          <i class="el-icon-warning"></i>客户端连接异常
        </div>
        <!-- <img src="@/static/scanSheet/client-side-succ.png" alt="" /> -->
      </div>
    </template>

    <div class="client-side-btn">
      <!-- 因为客户端没返回批量扫描的状态  先取消loading -->
      <!--             :loading="clientLoading"  -->
      <el-button
        v-if="templateExamine"
        type="primary"
        :disabled="getDis()"
        :loading="clientLoading"
        @click="sendMsg(3)"
      >
        开始扫描
      </el-button>
      <el-button
        v-else
        type="primary"
        :disabled="getDis()"
        :loading="clientLoading"
        @click="sendMsg(2)"
      >
        扫描并检验模版
      </el-button>
      <el-button
        type="primary"
        :disabled="!openClient || !$parent.sheetInfomationTop.isScan"
        @click="sendMsg(3, 'startUpload')"
      >
        离线上传
      </el-button>
      <el-checkbox
        v-if="scanInformation.localMachine > 0"
        v-model="templateExamine"
        :disabled="getDis()"
        class="no-check"
      >
        不校验模板
      </el-checkbox>
    </div>
    <p style="margin: 24px 0">
      <span class="succ" @click="checkTab">查看扫描批次</span>
    </p>
    <div class="scan-list-box">
      <template v-if="scanList.length > 0">
        <el-table :data="scanList" border style="width: 100%">
          <el-table-column prop="batch" label="批次" align="center">
          </el-table-column>
          <el-table-column prop="scannedNum" label="已扫描" align="center">
          </el-table-column>
          <el-table-column prop="uploadedNum" label="已上传" align="center">
          </el-table-column>
          <el-table-column
            prop="uploadPercentage"
            label="上传状态"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="distinguishPercentage"
            label="识别状态"
            align="center"
          >
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
        />
      </template>
      <div v-else class="scan-none-list">
        <img src="@/static/scanSheet/default-diagram.png" alt="" />
        <p>暂无数据</p>
      </div>
    </div>
    <el-dialog title="模板校验" :visible.sync="dialogVisible" width="70%">
      <div class="model-img">
        <img v-for="(v, i) in imgUrl" :key="i" :src="v" alt="" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delScan()">重新校验</el-button>
        <el-button type="primary" @click="checkClient">确 定</el-button>
      </span>
    </el-dialog>
    <showErrImg ref="showErrImg"></showErrImg>
    <scanningParameters ref="scanningParameters"></scanningParameters>
  </div>
</template>

<script>
import { websocketCommand } from "@/core/util/websocket.js";
import { getStore, setStore } from "@/core/util/store";
import {
  // scanrecordPage,
  examineScan,
  deleteScanrecord,
} from "@/core/api/exam/examScanSheet";
import {
  scanrecordunionPage,
  scanrecordunionAll,
} from "@/core/api/exam/examUnionScan";
//
import { downClient } from "@/core/util/util.js";
import showErrImg from "@/components/scan/showErrImg.vue";
import scanningParameters from "@/components/scan/scanningParameters";
import { examScanParameter } from "@/core/util/scanJson";
export default {
  name: "ClientSideInteraction",
  components: { showErrImg, scanningParameters },
  props: {
    sheetInfomation: {
      type: Object,
      default: () => {},
    },
    paperList: {
      type: Array,
      default: () => {},
    },
    indexViewType: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      clientLoading: false,
      openClient: true,
      imgUrl: [],
      // 扫描仪状态
      clientSideType: true,
      btnDisabled: true,
      // 扫描仪连接状态
      clientSideLinkType: false,
      scanInformation: {},
      indexPaper: {},
      clientData: {},
      // 模板检查状态
      templateExamine: false,
      drivePanel: false,
      pageIndex: 1,
      pageSize: 50,
      totalPage: 0,
      scanList: [],
      returnMsg: "",
      clientSideId: "",
      examScanParameter: examScanParameter,
      tipsArr: ["未连接扫描仪", "扫描仪无纸张", "扫描仪错误"],
    };
  },
  watch: {
    indexViewType() {
      if (this.indexViewType == 1) {
        this.scanrecordPage();
      } else {
        clearTimeout(this.getScan);
        clearTimeout(this.getPage);
      }
    },
    dialogVisible() {
      if (this.dialogVisible == false) {
        this.handleClose();
      }
    },
  },
  created() {
    // console.log("看拉萨的卡");
  },
  mounted() {
    if (
      this.sheetInfomation.templateVO &&
      this.sheetInfomation.templateVO.pageCount
    ) {
      this.createWs();
    }
  },
  destroyed() {
    websocketCommand(this.wsObj, "close");
    clearTimeout(this.getScan);
    clearTimeout(this.getPage);
    clearInterval(this.getImg);
  },
  methods: {
    loadScan() {
      this.templateExamine = false;
      clearTimeout(this.getScan);
      clearTimeout(this.getPage);
      // this.scanrecordAll();
      this.scanrecordPage();
      this.scanrecordAll();
    },
    checkTab() {
      this.$emit("checkTab");
    },
    examineScan() {
      if (this.$route.name != "unionscanSheet") {
        return;
      }
      let data = {
        examPaperId: this.$route.query.examPaperId,
        clientSideId: this.clientSideId,
      };
      examineScan(data)
        .then((res) => {
          this.indexImgsC++;
          if (res.data.data && res.data.data.markUrls) {
            this.scanId = "";
            this.dialogVisible = true;
            this.imgUrl = res.data.data.markUrls.split("，");
            this.scanId = res.data.data.id;
            this.clientLoading = false;
            clearInterval(this.getImg);
          }
          // 9.29 16:00要求 一直轮询
          if (!this.dialogVisible) {
            this.getImg = setTimeout(() => {
              this.examineScan();
            }, 1000);
          }
        })
        .catch(() => {
          this.clientLoading = false;
        });
    },
    downCleint() {
      downClient();
    },
    delScan() {
      this.dialogVisible = false;
      if (!this.scanId) {
        return;
      }
      let data = {
        // password: user.password,
        ids: [this.scanId],
        type: 1,
      };
      deleteScanrecord(data).then(() => {
        clearTimeout(this.getPage);
        this.scanrecordPage();
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.checkClient();
    },
    checkClient() {
      if (this.showModel) {
        const h = this.$createElement;
        this.$msgbox({
          title: "温馨提示",
          message: h("p", null, [
            h("span", null, "模版已校验成功！ "),
            h("p", null, "为保证答题卡准确上传,"),
            h(
              "span",
              { style: "color: red" },
              "请务必将已校验的答题卡之外的其他卡进行重新扫描！"
            ),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {})
          .catch(() => {});
      }
      this.showModel = false;
      this.dialogVisible = false;
      this.templateExamine = true;
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      clearTimeout(this.getPage);
      this.scanrecordPage();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      clearTimeout(this.getPage);
      this.scanrecordPage();
    },
    // 获取批次列表
    scanrecordPage() {
      if (this.$route.name != "unionscanSheet") {
        return;
      }
      if (this.indexViewType == 2) {
        return;
      }
      let data = {
        examPaperId: this.$route.query.examPaperId,
        current: this.pageIndex,
        size: this.pageSize,
        clientSideId: this.clientSideId,
      };
      scanrecordunionPage(data)
        .then((res) => {
          // this.scanList=
          this.totalPage = res.data.data.total;
          this.scanList = res.data.data.records;
          // this.getPage = setTimeout(() => {
          //   this.scanrecordPage();
          // }, 10000);
        })
        .catch(() => {
          // clearInterval(this.getTransfer);
        });
    },
    linkToErr() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      // console.log(query);
      this.paperList.map((item) => {
        if (item.value == query.examPaperId) {
          query.sheetUuid = item.sheetUuid;
        }
      });
      delete query.name;
      query.isView = this.$parent.sheetInfomationTop.isView ? 1 : 0;
      // query.isView = 0;

      if (!query.sheetUuid) {
        return;
      }
      const routeData = this.$router.resolve({
        name: "unionscanaBnormalPaper",
        query: query,
      });
      window.open(routeData.href, "_blank");
    },
    // 获取扫描信息
    scanrecordAll() {
      if (this.$route.name != "unionscanSheet") {
        return;
      }
      let data = {
        examPaperId: this.$route.query.examPaperId,
        clientSideId: this.clientSideId,
        id: this.$parent.scanPointId,
      };

      scanrecordunionAll(data)
        .then((res) => {
          this.scanInformation = res.data.data;
          // this.getScan = setTimeout(() => {
          //   this.scanrecordAll();
          // }, 10000);
        })
        .catch(() => {});
    },
    initDrivePanel() {
      let scanDrivePanel = getStore({ name: "scan-drivePanel" });
      this.drivePanel = false;

      if (scanDrivePanel) {
        scanDrivePanel.map((item) => {
          if (item.scannerModel == this.clientData.scannerModel) {
            this.drivePanel = item.drivePanel;
          }
        });
      }
    },
    setDrivePanel() {
      let scanDrivePanel = getStore({ name: "scan-drivePanel" });
      //   从缓存中去取一次
      let addType = true;
      if (scanDrivePanel) {
        scanDrivePanel.map((item) => {
          if (item.scannerModel == this.clientData.scannerModel) {
            addType = false;
            item.drivePanel = this.drivePanel;
          }
        });
      } else {
        scanDrivePanel = [];
      }

      if (addType) {
        scanDrivePanel.push({
          drivePanel: this.drivePanel,
          scannerModel: this.clientData.scannerModel || 1,
        });
      }
      setStore({ name: "scan-drivePanel", content: scanDrivePanel });
    },
    setScanparameter() {
      if (!this.clientData.scannerModel) {
        this.$message({
          showClose: true,
          message: "扫描仪类型获取失败,请重启后再试!",
          type: "warning",
        });
        return;
      }
      if (!this.clientData) {
        return;
      }
      let data = {
        size:
          this.$parent.sheetInfomationTop.templateVO.paperType == 1
            ? "A4"
            : "A3",
        style:
          this.$parent.sheetInfomationTop.templateVO.numberCount +
          "张" +
          this.$parent.sheetInfomationTop.templateVO.pageCount +
          "面",
      };

      this.$refs.scanningParameters.init(this.clientData, data);
    },
    createWs() {
      websocketCommand(this.wsObj, "close");
      this.clientSideType = true;
      this.clientLoading = false;
      this.clientSideLinkType = false;
      this.btnDisabled = true;
      this.loadScan();
      this.wsObj = new WebSocket("ws://127.0.0.1:9468/wtyscans");
      this.sendObj = {
        action: "communicationScan",
      };
      websocketCommand(
        this.wsObj,
        "create",
        5000,
        this.sendHeartBeat,
        this.receiveMsg,
        this.reconnectWs,
        this.sendObj
      );
    },
    // 收到服务端发送的数据后，需要处理的逻辑
    // scannerStatus
    //     0 未连接扫描仪
    // 1 扫描仪无纸张
    // 2 扫描仪错误
    // 3 已连接扫描仪
    // 4 扫描上传中
    // 5 扫描完成
    // 6 校验扫描上传完成
    // 7 扫描上传失败
    // 8 扫描取消
    receiveMsg(data) {
      // console.log(data);
      this.clientData = data;
      if (!this.clientData.scannerModel) {
        this.clientData.scannerModel = 1;
      }
      this.returnMsg = "";
      this.btnDisabled = false;
      this.openClient = true;
      if (data.returnCode == 200) {
        if (data.scannerStatus == 0) {
          this.clientSideLinkType = false;
          this.returnMsg = "未连接扫描仪";
          this.clientLoading = false;
          this.clientSideType = false;
          this.btnDisabled = true;
        }
        if (data.scannerStatus == 1) {
          this.returnMsg = "扫描仪无纸张";
          this.clientLoading = false;
          this.$alert(`扫描仪无纸张`, "提示", {
            confirmButtonText: "确定",
            callback: () => {
              this.clientLoading = false;
              this.clientSideType = true;
            },
          });
        }
        if (data.scannerStatus == 2) {
          this.returnMsg = "扫描仪错误";
          this.$alert(`扫描仪错误`, "提示", {
            confirmButtonText: "确定",
            callback: () => {},
          });
          // this.btnDisabled = true;
          this.clientLoading = false;
          this.clientSideType = false;
        }
        // 已连接扫描仪
        if (data.scannerStatus == 3) {
          this.clientSideType = true;
          this.clientSideLinkType = true;
          this.clientSideId = data.clientId;
          clearTimeout(this.getScan);
          this.scanrecordAll();
          clearTimeout(this.getPage);
          this.scanrecordPage();
        }
        // 扫描仪有任务
        if (data.scannerStatus == 4) {
          this.clientSideType = true;
          this.clientSideLinkType = true;
          this.clientSideId = data.clientId;
          clearTimeout(this.getScan);
          this.scanrecordAll();
          clearTimeout(this.getPage);
          this.scanrecordPage();
          this.$alert(`扫描任务正在进行中，请稍后！`, "提示", {
            confirmButtonText: "确定",
            callback: () => {},
          });
        }
        if (data.scannerStatus == 5) {
          if (this.indexReq != 2) {
            this.clientLoading = false;
            this.scannerStatus = data.scannerStatus;
            this.$message({
              message: `扫描完成!`,
              type: "success",
              duration: 2000,
              showClose: true,
            });
          }
        }
        if (data.scannerStatus == 6) {
          this.$message({
            showClose: true,
            message: `模板已上传，请等待校验！`,
            type: "success",
          });
          this.clientLoading = true;
          this.examineScanNumber = 1;
          this.indexImgsC = 1;
          this.examineScan();
        }
        if (data.scannerStatus == 7) {
          this.$message({
            showClose: true,
            message: `扫描上传失败`,
            type: "error",
            duration: 3000,
          });
          this.clientLoading = false;
        }
        if (data.scannerStatus == 8) {
          this.$message({
            showClose: true,
            message: `扫描已取消`,
            type: "success",
          });
          this.clientLoading = false;
        }
        if (data.scannerStatus == 9) {
          this.$alert(`磁盘空间低于500M，请清理后上传！`, "提示", {
            confirmButtonText: "确定",
            callback: () => {},
          });
          // this.btnDisabled = true;
          this.clientLoading = false;
          this.clientSideType = false;
        }
        if (data.scannerStatus == 10) {
          if (data.lastLocalImagePath) {
            this.$refs.showErrImg.init(data.lastLocalImagePath);
          } else {
            let msg = data.message;
            if (!msg) {
              msg = "扫描错误，请重新扫描此批次！";
            }
            this.$alert(msg, "提示", {
              confirmButtonText: "确定",
              callback: () => {},
            });
            this.showModel = false;
            if (this.indexReq == 3) {
              const h = this.$createElement;

              this.$msgbox({
                title: "异常警告",
                message: h("p", null, [
                  h("span", null, "扫描仪出错啦！ "),
                  h("p", null, "为保证答题卡图片准确上传,"),
                  h(
                    "span",
                    { style: "color: red" },
                    "请务必将本批次已扫描（含当前答题卡及之前）的10张答题卡进行重新扫描！"
                  ),
                ]),
                showCancelButton: true,
                confirmButtonText: "确定",
                cancelButtonText: "取消",
              })
                .then(() => {})
                .catch(() => {});
            } else {
              this.showModel = true;
            }
          }

          this.clientLoading = false;
          this.clientSideType = false;
        }
      } else {
        this.clientSideType = false;
        this.clientSideLinkType = false;
        this.clientLoading = false;
        this.openClient = false;
        this.btnDisabled = true;
        if (!data.returnCode) {
          return;
        }

        let str = "";
        if (data.returnCode == 201) {
          str = "未识别的action";
        }
        if (data.returnCode == 202) {
          str = "请求参数错误";
        }
        if (data.returnCode == 203) {
          str = "参数解析错误";
        }
        if (data.returnCode == 204) {
          str = "扫描任务正在进行中，请稍后！";
        }
        if (!str) {
          str = "未知错误，请联系管理员";
        }
        this.$alert(`${str}！`, "提示", {
          confirmButtonText: "确定",
          callback: () => {},
        });
      }
      this.initDrivePanel();
    },

    // 断网重连，需要处理的逻辑
    reconnectWs() {
      if (this.wsObj) {
        // console.log("%c websocket_reconnect", "color:blue");
        this.createWs();
        // todo
        // 如：this.$store.dispatch("app/changeAppMsg",['cloudWebsocketReconnect',true]);
      }
    },
    // 以回调方式向服务端发送(对象类型的)心跳
    sendHeartBeat() {
      this.wsObj.send(JSON.stringify(this.sendObj));
    },
    getDis() {
      let type = false;
      if (this.btnDisabled == true) {
        type = true;
      }
      if (!this.$parent.sheetInfomationTop.isScan) {
        type = true;
      }
      return type;
    },
    sendMsg(val, scanType) {
      const token = getStore({ name: "access_token" });
      const schoolId = getStore({ name: "school_id" });
      let userInfo = getStore({
        name: "userInfo",
      });

      this.indexReq = val;
      let data = {
        token: token,
        schoolId: schoolId,
        examPaperId: this.$route.query.examPaperId,
        firstToExamine: val,
        pageCount: this.$parent.sheetInfomationTop.templateVO.pageCount,
        scanPointId: this.$parent.scanPointId,
        type:
          this.$parent.sheetInfomationTop.templateVO.paperType == 1
            ? "A4"
            : "A3",
        colorType: this.$parent.sheetInfomationTop.templateVO.colorType,
        examName: this.$parent.sheetInfomationTop.examName,
        subjectName: this.$parent.sheetInfomationTop.subjectName,
        schoolName: this.$parent.sheetInfomationTop.schoolName,
        examDate: this.$parent.examInformation.examDate,
        numberCount: this.$parent.sheetInfomationTop.templateVO.numberCount,
        paperColorType: this.$parent.sheetInfomationTop.templateVO.colorType,
        drivePanel: this.drivePanel ? 1 : 0,
      };
      let parameter = {};
      let scanParameter = getStore({ name: "scan-parameter" });
      //   从缓存中去取一次 获取缓存的id
      let addType = true;
      if (scanParameter) {
        scanParameter.map((item) => {
          if (item.scannerModel == this.clientData.scannerModel) {
            addType = false;
            Object.assign(data, item.form);
          }
        });
      }
      if (addType) {
        Object.assign(data, this.examScanParameter);
      }
      Object.assign(data, parameter);
      if (userInfo.roleId == 1 || userInfo.roleId == 6) {
        data.scanPointId = -1;
      }
      if (data.paperAdaptive) {
        data.type = "auto";
      }
      delete data.size;
      delete data.style;
      this.sendObj = {
        action: scanType || "startScan",
        data: data,
      };

      if (!scanType) {
        this.clientLoading = true;
      }
      websocketCommand(
        this.wsObj,
        "push",
        5000,
        this.sendHeartBeat,
        this.receiveMsg,
        this.reconnectWs,
        this.sendObj
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes fadenum {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.no-check {
  margin-top: 12px;
  display: block;
  margin-bottom: 0;
  vertical-align: bottom;
}
.clientSideInteraction {
  padding: 24px;
  background-color: #ffffff;
  width: calc(100% - 324px);

  .model-img {
    height: calc(100vh - 400px);
    overflow-y: scroll;

    img {
      width: 100%;
    }
  }

  .refresh {
    cursor: pointer;
  }

  .succ {
    color: $primary-color;
    border-bottom: 1px solid $primary-color;
    cursor: pointer;
  }

  .err {
    color: #ff0000;
    margin-right: 24px;
  }

  .scan-list-box {
    .el-pagination {
      display: flex;
      justify-content: flex-end;
      padding: 16px 0 0;
    }

    .scan-none-list {
      margin: 60px auto;
      text-align: center;

      p {
        color: #ababab;
      }
    }

    // img {
    //   display: block;

    // }
  }

  .client-side-btn {
    text-align: center;
    padding: 18px 0;
    background: #fbfbfb;
  }

  .client-side-box-waring.client-side-box {
    background-image: url("~@/static/scanSheet/client-side-waring.png");
    justify-content: center;
    align-items: center;
  }
  .scan-msg-box {
    justify-content: space-between;
    align-items: center;
    padding: 20px 10vw;

    .scan-msg-list {
      text-align: center;
      cursor: pointer;

      .scan-tips {
        font-size: 12px;
        padding: 2px 10px;
        border-radius: 11px;
        border: 1px solid #ffffff;
        background: rgba(255, 255, 255, 0.1);
      }

      span {
        // margin-top: 4px;
        color: #ffffff;
        font-size: 12px;
      }

      .scan-msg {
        width: 124px;
        height: 124px;
        align-items: center;
        // border: 2px solid rgba(255, 255, 255, 0.2);
        position: relative;
        border-radius: 50%;
        text-align: center;
        margin-bottom: 15px;
        background: url("~@/static/scanSheet/nums_icon.png") no-repeat;
        background-size: 100% 100%;
        padding-top: 2px;
        p {
          font-size: 18px;
          line-height: 28px;
          color: #ffffff;
          margin-top: 34px;
          margin-bottom: 0;
        }

        &::after {
          // border-radius: 50%;
          // position: absolute;
          // content: "";
          // width: 100px;
          // height: 100px;
          // background-image: url("~@/static/scanSheet/nums_icon.png");
          // background: rgba(255, 255, 255, 0.1);
          // border: 4px solid rgba(255, 255, 255, 0.2);
          // left: 0;
          // top: 0;
        }
      }
    }
  }

  .client-side-box {
    width: 100%;
    height: 199px;
    background-image: url("~@/static/scanSheet/client-side-succ.png");
    display: flex;

    .load-cient {
      color: #ffffff;
      font-size: 18px;
      display: flex;
      align-items: center;

      i {
        margin-right: 12px;
      }

      img {
        margin-right: 12px;
        animation: fadenum linear 1s infinite;
      }
    }
  }

  .client-side-box-loading {
    justify-content: center;
    align-items: center;
  }

  .client-side-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #2f3130;
    margin-bottom: 24px;

    .waring {
      color: #f0bd62;
    }
  }
}
</style>
